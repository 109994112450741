import axios from '@axios'

/**
 * ==== Voucher Report ===
 */

const fetchVoucherPurchasesSummary = queryParams => axios.get('/merchant/voucher-reporting/purchases-summary', { params: queryParams })

const fetchVoucherReports = queryParams => axios.get('/merchant/voucher-reports', { params: queryParams })

const downloadVoucherReport = id =>
  axios.get(`/merchant/voucher-reports/${id}/download.xlsx`, {
    responseType: 'blob',
  })

const resendVoucherReport = (id, data) => axios.post(`/merchant/voucher-reports/${id}/resend`, data)

const storeVoucherReport = data => axios.post('/merchant/voucher-reports', data)

/**
 * ==== Service Report ===
 */

const fetchServicePurchasesSummary = queryParams => axios.get('/merchant/service-reporting/purchases-summary', { params: queryParams })

const fetchServiceReports = queryParams => axios.get('/merchant/service-reports', { params: queryParams })

const downloadServiceReport = id =>
  axios.get(`/merchant/service-reports/${id}/download.xlsx`, {
    responseType: 'blob',
  })

const resendServiceReport = (id, data) => axios.post(`/merchant/service-reports/${id}/resend`, data)

const storeServiceReport = data => axios.post('/merchant/service-reports', data)

/**
 * ==== Tangible Report ===
 */

const fetchTangiblePurchasesSummary = queryParams => axios.get('/merchant/tangible-reporting/purchases-summary', { params: queryParams })

const fetchTangibleReports = queryParams => axios.get('/merchant/tangible-reports', { params: queryParams })

const downloadTangibleReport = id =>
  axios.get(`/merchant/tangible-reports/${id}/download.xlsx`, {
    responseType: 'blob',
  })

const resendTangibleReport = (id, data) => axios.post(`/merchant/tangible-reports/${id}/resend`, data)

const storeTangibleReport = data => axios.post('/merchant/tangible-reports', data)

export {
  downloadServiceReport,
  downloadTangibleReport,
  downloadVoucherReport,
  fetchServicePurchasesSummary,
  fetchServiceReports,
  fetchTangiblePurchasesSummary,
  fetchTangibleReports,
  fetchVoucherPurchasesSummary,
  fetchVoucherReports,
  resendServiceReport,
  resendTangibleReport,
  resendVoucherReport,
  storeServiceReport,
  storeTangibleReport,
  storeVoucherReport,
}
